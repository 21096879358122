import React from 'react';
import Layout from '../components/layout';

function contact() {
    return (
        <Layout>
            <div className="grid-item">
                <div className="grid-content">
                    <div style={{backgroundColor: 'rgba(108,90,52,.85)', padding: '1%'}}>
                        <div style={{fontSize: '2.5em'}}>Thank you!</div>
                        <div>I will contact you soon</div>
                    </div>
                </div>
            </div>
        </Layout>
    );
}

export default contact;